import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import "../../css/range-input.css";
import AdBanner from "../../components/ad-banner";

var tempAudio = null;

class View extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			text: "",
			key: "",
			languageCode: "en-GB",
			voiceName: "en-GB-Wavenet-B",
			gender: "MALE",
			errorMessage: "",
			speed: 1,
			pitch: 0,
			buttonDisabled: false,
			isPlaying: false,
			showAdvancedOptions: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	handleChange(event) {
		if (event.target.id === "key") {
			var key = event.target.value.replace(/\n/g, "");
			key = key.replace(/ /g, "");
			this.setState({ key: key });
		} else if (event.target.id == "textarea") {
			this.setState({ text: event.target.value });
		} else if (event.target.id == "speed") {
			this.setState({ speed: event.target.value });
		} else if (event.target.id == "pitch") {
			this.setState({ pitch: event.target.value });
		} else if (event.target.id == "voice") {
			var languageCode = event.target.value.split("|")[0].trim();
			var voiceName = event.target.value.split("|")[1].trim();
			var ssmlGender = event.target.value.split("|")[2].trim();
			this.setState({
				languageCode: languageCode,
				voiceName: voiceName,
				gender: ssmlGender
			});
		}

		this.setState({ errorMessage: "" });
	}

	getDangerousHTML = str => {
		return { __html: str };
	};

	handleClick(event) {
		if (event.target.id == "download-audio") {
			this.setState({ buttonDisabled: true });
			postData(
				`https://texttospeech.googleapis.com/v1/text:synthesize
				?key=${this.state.key}`,
				{
					audioConfig: {
						audioEncoding: "LINEAR16",
						speakingRate: this.state.speed,
						pitch: this.state.pitch
					},
					input: {
						text: this.state.text
					},
					voice: {
						languageCode: this.state.languageCode,
						name: this.state.voiceName,
						ssmlGender: this.state.gender
					}
				}
			)
				.then(response => {
					response
						.blob()
						.then(audioBlob => {
							var reader = new FileReader();
							reader.addEventListener("loadend", () => {
								if (response.status != 200) {
									this.setState({
										isPlaying: false,
										errorMessage: JSON.parse(reader.result).error.message
									});
								} else {
									var blob = b64toBlob(
										JSON.parse(reader.result).audioContent,
										"audio/wav"
									);
									const url = window.URL.createObjectURL(blob);
									const link = document.createElement("a");
									link.href = url;
									link.setAttribute("download", "audio.wav");
									document.body.appendChild(link);
									link.click();
								}
								this.setState({ buttonDisabled: false });
							});
							reader.readAsText(audioBlob);
						})
						.catch(err => {
							console.log("Another error occured.");
						});
				})
				.catch(err => {
					console.log("An error occured.");
				});
		} else if (event.target.id == "play-audio") {
			this.setState({ isPlaying: true });
			postData(
				`https://texttospeech.googleapis.com/v1/text:synthesize
					?key=${this.state.key}`,
				{
					audioConfig: {
						audioEncoding: "LINEAR16",
						speakingRate: this.state.speed,
						pitch: this.state.pitch
					},
					input: {
						text: this.state.text
					},
					voice: {
						languageCode: this.state.languageCode,
						name: this.state.voiceName,
						ssmlGender: this.state.gender
					}
				}
			)
				.then(response => {
					response
						.blob()
						.then(audioBlob => {
							var reader = new FileReader();
							reader.addEventListener("loadend", () => {
								if (response.status != 200) {
									this.setState({
										isPlaying: false,
										errorMessage: JSON.parse(reader.result).error.message
									});
								} else {
									var blob = b64toBlob(
										JSON.parse(reader.result).audioContent,
										"audio/wav"
									);
									const audioURL = window.URL.createObjectURL(blob);
									// const link = document.createElement("a");
									// link.href = url;
									// link.setAttribute("download", "audio.wav");
									// document.body.appendChild(link);
									// link.click();
									tempAudio = new Audio(audioURL);
									tempAudio.play();
									tempAudio.addEventListener(
										"ended",
										e => {
											this.setState({ isPlaying: false });
										},
										false
									);
								}
								this.setState({ buttonDisabled: false });
							});
							reader.readAsText(audioBlob);
						})
						.catch(err => {
							console.log("Another error occured.");
							this.setState({ isPlaying: false });
						});
				})
				.catch(err => {
					console.log("An error occured.");
					this.setState({ isPlaying: false });
				});
		} else if (event.target.id == "stop-audio") {
			if (tempAudio != null) {
				tempAudio.pause();
				this.setState({ isPlaying: false });
			}
		} else if (event.target.id == "textarea") {
			event.target.select();
		} else if (event.target.id == "clear-text") {
			console.log("clearing text.");
			this.setState({ text: "" });
		} else if (event.target.id == "toggle-advanced-options") {
			this.setState({ showAdvancedOptions: !this.state.showAdvancedOptions });
		}
	}

	render() {
		return (
			<Layout>
				<SEO
					title="Free Text to Speech Reader | Free Text to Speech Download |"
					keywords={[
						"free text to speech",
						"free text to speech download",
						"free text to speech with download",
						"text to speech free online",
						"free text to speech software",
						"free text to speech online",
						"free text to speech app",
						"free text to speech reader"
					]}
					description={"Free Text to Speech Reader"}
				/>
				{/* <Navigation /> */}
				
				{/* <AdBanner /> */}
				<Container>
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col>
							<h3
								style={{
									textAlign: "center",
									fontWeight: "300"
								}}
							>
								<Link to="/products">{"⬅ Text to Speech Reader"}</Link>
							</h3>
						</Col>
					</Row>
				</Container>
				<Container
					style={{
						display: "flex",
						justifyContent: "center"
					}}
				>
					<div
						className="card-2"
						style={{
							padding: "1rem",
							backgroundColor: "white",
							width: "100%",
							maxWidth: 650,
							border: "1px solid #eeeeee"
						}}
					>
						{this.state.errorMessage.length != "" ? (
							<Alert variant={"danger"}>{this.state.errorMessage}</Alert>
						) : null}
						<Form.Group>
							<Form.Control
								type="password"
								id="key"
								placeholder="Google API Key"
								value={this.state.key}
								onChange={this.handleChange}
							/>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<a
									href="https://cloud.google.com/docs/authentication/api-keys#creating_an_api_key"
									target="_blank"
								>
									<small>{"Where is my API Key?"}</small>
								</a>
							</div>
						</Form.Group>
						<Form.Group>
							<Form.Control
								id="textarea"
								as="textarea"
								rows="5"
								value={this.state.text}
								onChange={this.handleChange}
								onClick={this.handleClick}
								placeholder={"Text goes here."}
							/>
						</Form.Group>
						<Form.Group style={{ marginTop: -10 }}>
							<Row>
								<Col>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											marginRight: 20
										}}
									>
										{this.state.text != null ? (this.state.text.length
										) : null}
									</div>
								</Col>
								<Col>
									<div style={{ display: "flex", justifyContent: "flex-end" }}>
										<small>
											<Button
												variant="link"
												size="sm"
												id="clear-text"
												onClick={this.handleClick}
												style={{ color: "black" }}
											>
												Clear Text
									</Button>
										</small>
									</div>
								</Col>
							</Row>
						</Form.Group>
						<Form.Group>
							<Row>
								<Col>
									{this.state.isPlaying ? (
										<Button
											id="stop-audio"
											variant="primary"
											block
											onClick={this.handleClick}
										>
											Stop Playing ⏹
										</Button>
									) : (
											<Button
												id="play-audio"
												variant="primary"
												block
												onClick={this.handleClick}
											>
												Play Audio ▶️
										</Button>
										)}
								</Col>
							</Row>
						</Form.Group>
						<Row style={{ paddingTop: 20 }} />
						<Form.Group>
							<hr />
						</Form.Group>
						<Form.Group>


							<Row>
								{(this.state.showAdvancedOptions)
									?
									<Col style={{ display: 'flex', justifyContent: 'center' }}>
										<Button id="toggle-advanced-options" variant="link" onClick={this.handleClick}>⬆ Hide Advanced Options</Button>
									</Col>
									:
									<Col style={{ display: 'flex', justifyContent: 'center' }}>
										<Button id="toggle-advanced-options" variant="link" onClick={this.handleClick}>⬇ Show Advanced Options</Button>
									</Col>
								}
							</Row>

						</Form.Group>

						{(this.state.showAdvancedOptions)
							?
							<div style={{ padding: 20 }}>
								<Form.Group>
									<Form.Label>Voice</Form.Label>
									<Form.Control
										id="voice"
										as="select"
										onChange={this.handleChange}
										value={"en-GB | en-GB-Wavenet-B | MALE"}
									>
										<option>nl-NL | nl-NL-Standard-A | FEMALE</option>
										<option>nl-NL | nl-NL-Wavenet-A | FEMALE</option>
										<option>en-AU | en-AU-Standard-A | FEMALE</option>
										<option>en-AU | en-AU-Standard-B | MALE</option>
										<option>en-AU | en-AU-Standard-C | FEMALE</option>
										<option>en-AU | en-AU-Standard-D | MALE</option>
										<option>en-AU | en-AU-Wavenet-A | FEMALE</option>
										<option>en-AU | en-AU-Wavenet-B | MALE</option>
										<option>en-AU | en-AU-Wavenet-C | FEMALE</option>
										<option>en-AU | en-AU-Wavenet-D | MALE</option>
										<option>en-GB | en-GB-Standard-A | FEMALE</option>
										<option>en-GB | en-GB-Standard-B | MALE</option>
										<option>en-GB | en-GB-Standard-C | FEMALE</option>
										<option>en-GB | en-GB-Standard-D | MALE</option>
										<option>en-GB | en-GB-Wavenet-A | FEMALE</option>
										<option>en-GB | en-GB-Wavenet-B | MALE</option>
										<option>en-GB | en-GB-Wavenet-C | FEMALE</option>
										<option>en-GB | en-GB-Wavenet-D | MALE</option>
										<option>en-US | en-US-Standard-B | MALE</option>
										<option>en-US | en-US-Standard-C | FEMALE</option>
										<option>en-US | en-US-Standard-D | MALE</option>
										<option>en-US | en-US-Standard-E | FEMALE</option>
										<option>en-US | en-US-Wavenet-A | MALE</option>
										<option>en-US | en-US-Wavenet-B | MALE</option>
										<option>en-US | en-US-Wavenet-C | FEMALE</option>
										<option>en-US | en-US-Wavenet-D | MALE</option>
										<option>en-US | en-US-Wavenet-E | FEMALE</option>
										<option>en-US | en-US-Wavenet-F | FEMALE</option>
										<option>fr-FR | fr-FR-Standard-A | FEMALE</option>
										<option>fr-FR | fr-FR-Standard-B | MALE</option>
										<option>fr-FR | fr-FR-Standard-C | FEMALE</option>
										<option>fr-FR | fr-FR-Standard-D | MALE</option>
										<option>fr-FR | fr-FR-Wavenet-A | FEMALE</option>
										<option>fr-FR | fr-FR-Wavenet-B | MALE</option>
										<option>fr-FR | fr-FR-Wavenet-C | FEMALE</option>
										<option>fr-FR | fr-FR-Wavenet-D | MALE</option>
										<option>fr-CA | fr-CA-Standard-A | FEMALE</option>
										<option>fr-CA | fr-CA-Standard-B | MALE</option>
										<option>fr-CA | fr-CA-Standard-C | FEMALE</option>
										<option>fr-CA | fr-CA-Standard-D | MALE</option>
										<option>de-DE | de-DE-Standard-A | FEMALE</option>
										<option>de-DE | de-DE-Standard-B | MALE</option>
										<option>de-DE | de-DE-Wavenet-A | FEMALE</option>
										<option>de-DE | de-DE-Wavenet-B | MALE</option>
										<option>de-DE | de-DE-Wavenet-C | FEMALE</option>
										<option>de-DE | de-DE-Wavenet-D | MALE</option>
										<option>it-IT | it-IT-Standard-A | FEMALE</option>
										<option>it-IT | it-IT-Wavenet-A | FEMALE</option>
										<option>ja-JP | ja-JP-Standard-A | FEMALE</option>
										<option>ja-JP | ja-JP-Wavenet-A | FEMALE</option>
										<option>ko-KR | ko-KR-Standard-A | FEMALE</option>
										<option>ko-KR | ko-KR-Wavenet-A | FEMALE</option>
										<option>pt-BR | pt-BR-Standard-A | FEMALE</option>
										<option>es-ES | es-ES-Standard-A | FEMALE</option>
										<option>sv-SE | sv-SE-Standard-A | FEMALE</option>
										<option>sv-SE | sv-SE-Wavenet-A | FEMALE</option>
										<option>tr-TR | tr-TR-Standard-A | FEMALE</option>
										<option>tr-TR | tr-TR-Wavenet-A | FEMALE</option>
									</Form.Control>
								</Form.Group>
								<Row>
									<Col xl={12}>
										<Form.Group>
											<Form.Label>Speed ({this.state.speed})</Form.Label>
											<Form.Control
												type="range"
												min=".3"
												max="4"
												step=".1"
												id="speed"
												value={this.state.speed}
												onChange={this.handleChange}
												style={{ border: "none" }}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>Pitch ({this.state.pitch})</Form.Label>
											<Form.Control
												type="range"
												min="-20"
												max="20"
												step="1"
												id="pitch"
												value={this.state.pitch}
												onChange={this.handleChange}
												style={{ border: "none" }}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row style={{ marginTop: 20 }}>
									<Col>
										{this.state.buttonDisabled ? (
											<Button variant="primary" block disabled>
												⬇ Download File
										</Button>
										) : (
												<Button
													id="download-audio"
													variant="primary"
													block
													onClick={this.handleClick}
												>
													⬇ Download File
										</Button>
											)}
									</Col>
								</Row>
							</div>
							:
							<div></div>
						}

					</div>
				</Container>
				<div style={{ marginTop: 100 }} />
				<Container>
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col style={{ maxWidth: 650 }}>
							<p>
								{
									"A Google Cloud (GCP) Account gives you access to 2 Million FREE characters for Text-to-Speech purposes. Use this Google Text-to-Speech API Client to take advantage of that!"
								}
							</p>
							<p>{"Also, I do not store your API Keys."}</p>
						</Col>
					</Row>
				</Container>
				<div style={{ marginTop: 100 }} />
				<Container>
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col style={{ maxWidth: 650, textAlign: "center" }}>
							<p>
								{
									"Get the desktop version of this tool at the link below. Using it is an epic experience and it also helps support and maintain the project. ❤"
								}
							</p>
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								display: "flex",
								justifyContent: "center"
							}}
						>
							<a href="https://gumroad.com/l/google-text-to-speech-api-client-desktop-app"><Button variant="link">Get Mac / Windows Desktop App</Button></a>
						</Col>
					</Row>
				</Container>
				<div style={{ marginTop: 100 }} />
				{/* <DonationFooter /> */}
			</Layout>
		);
	}
}

function b64toBlob(b64Data, contentType, sliceSize) {
	contentType = contentType || "";
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

function postData(url = ``, data = {}) {
	// Default options are marked with *
	return fetch(url, {
		method: "POST", // *GET, POST, PUT, DELETE, etc.
		mode: "cors", // no-cors, cors, *same-origin
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		credentials: "same-origin", // include, *same-origin, omit
		headers: {
			"Content-Type": "application/json"
			// "Content-Type": "application/x-www-form-urlencoded",
		},
		redirect: "follow", // manual, *follow, error
		referrer: "no-referrer", // no-referrer, *client
		body: JSON.stringify(data) // body data type must match "Content-Type" header
	});
	// .then(response => response.json()); // parses response to JSON
}

export default View;
